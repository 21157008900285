{
  "advanced": {
    "log": {
      "level": "Log Level"
    }
  },
  "alert": {
    "confirm": "Are you sure you want to perform this action?",
    "multipleDeleteconfirm": "Are you sure you want to delete {n} item? | Are you sure you want to delete {n} items?",
    "partialError": "Your request could not be entirely completed. Try one by one to get detailed errors."
  },
  "allowedPlayers": {
    "delete": {
      "message": "Please confirm the deletion on the allowed players"
    }
  },
  "analytics": {
    "brawlStars": {
      "andSoOnForBrawlers": "and so on for all 64 brawlers",
      "data": {
        "banRate": "Ban Rate",
        "brawler": "Brawler",
        "matches": "Matches",
        "pickRate": "Pick rate",
        "rank": "Rank",
        "winRate": "Win Rate"
      },
      "filters": {
        "gameMode": "Game Mode",
        "map": "Map"
      },
      "titles": {
        "averageDamagePerGame": "Average Damage Per Game",
        "averageDamagePerMatch": "Average Damage Per Match",
        "brawlersStats": "Brawlers Stats",
        "top3Bans": "Top 3 Brawlers Bans",
        "top3Losses": "Top 3 Brawlers (loss count)",
        "top3Picks": "Top 3 Brawlers Picks",
        "top3Wins": "Top 3 Brawlers (win count)"
      }
    },
    "clashRoyale": {
      "data": {
        "card": "Card",
        "selectionRate": "Selection Rate"
      },
      "titles": {
        "averageElixirCost": "Average Deck Elixir Cost",
        "averageTowerDamage": "Average Tower Damage",
        "cardUsage": "Card Usage"
      }
    },
    "data": {
      "all": "All",
      "average": "Average",
      "averageDamage": "Average Damage",
      "averageDeckElixir": "Average Elixir Cost",
      "averageTowerDamage": "Average Tower Damage",
      "banned": "Banned",
      "bye": "BYEs",
      "c": "Cumulative",
      "complete": "Complete",
      "completed": "Completed",
      "cumulative": "Cumulative",
      "forfeit": "Forfeits",
      "forfeited": "Forfeited",
      "incomplete": "Incomplete",
      "live": "Live",
      "losses": "Losses",
      "noData": "No Data",
      "not_ready": "Not Ready",
      "notReadyRosters": "Not Ready",
      "other": "Other",
      "overturns": "Overturns",
      "ready": "Ready",
      "readyRosters": "Ready",
      "registered": "Registered",
      "scheduled": "Scheduled",
      "t": "Trend",
      "tied": "Ties",
      "ties": "Ties",
      "total": "Total",
      "totalPlayers": "Total Players",
      "totalRosters": "Registered",
      "trend": "Trend",
      "unverified": "Unverified",
      "upcoming": "Upcoming",
      "verified": "Verified",
      "wins": "Wins"
    },
    "filters": {
      "cumulative": "Cumulative",
      "frequency": "Frequency"
    },
    "frequencySelectors": {
      "daily": "Daily",
      "hourly": "Hourly"
    },
    "gameStatistics": "Game Statistics",
    "periodSelectors": {
      "last8Hours": "Last 8 Hours",
      "last24Hours": "Last 24 Hours",
      "lastWeek": "Last Week"
    },
    "select": {
      "aDateSpan": "Choose a Date Span",
      "anEvent": "Choose an Event"
    },
    "table": {
      "noData": "No data to display...",
      "showAll": "Show All",
      "showLess": "Show Less"
    },
    "titles": {
      "gameStats": "Game Stats",
      "matches": "Matches",
      "playerRegistrations": "Player Registrations",
      "players": "Players",
      "teams": "Teams",
      "teamsRegistrations": "Team Registrations"
    }
  },
  "auth": {
    "changePassword": "Change Password",
    "currentPassword": "Current Password",
    "newPassword": "New Password",
    "passwordConfirmation": "Confirm Password",
    "passwordReset": {
      "failed": "An error occurred while trying to reset password. Please try again",
      "success": "Password was reset successfully",
      "wrongOldPassword": "You entered your old password incorrectly. Please check it and try again"
    }
  },
  "blocked_by_admin": "blocked by admin",
  "brackets": {
    "final": "Final",
    "grandFinal": "Grand Final",
    "grandFinal2": "Grand Final Game 2",
    "lower": "lower bracket",
    "quarterFinal": "Quarter Final",
    "semiFinal": "Semi Final",
    "upper": "upper bracket"
  },
  "bSelect": {
    "all": "All"
  },
  "cadenceDescription": {
    "concurrent": "All individual matches will be played at the same time.",
    "manual": "Edit each match time individually.",
    "sequential": "All individual matches will be played one after each other."
  },
  "clubs": {
    "alert": {
      "byTurningOn": "By turning Clubs on, they will appear in your player experience and you can configure club sizes, turn chat on, and set up duels (a great way for club members to practice).",
      "enableClubs": "Enable {0} to allow your players to create, join, and manage clubs outside of Athlos events."
    },
    "chat": "Chat",
    "clubSize": "Club size",
    "clubsOff": "Clubs is off.",
    "clubsOn": "Clubs is on in your player experience.",
    "configuration": {
      "success": "Clubs configuration updated successfully"
    },
    "configureTitle": "Configure Clubs",
    "create": {
      "success": "Club created successfully"
    },
    "currentClubImage": "Current club image",
    "duels": "Duels",
    "duelsPlayed": "Duels played",
    "edit": {
      "success": "Club updated successfully"
    },
    "enablePlayersToChallenge": "Enable players to challenge each other to a match.",
    "enablePlayersToChat": "Enable players to chat within their club.",
    "example": "Club {'|'} Clubs",
    "image": "Club image",
    "languageKey": "How do you want clubs to be called in your player experience?",
    "maxClubs": "Max # of Clubs per User",
    "maxSize": "Max # of Players per Club",
    "membersCount": "# of members",
    "requiredMatchIntegration": "*requires @:common.match integration",
    "terminology": "Terminology",
    "userClubs": "User clubs"
  },
  "color": {
    "choose": "Choose your color"
  },
  "common": {
    "account": "Account",
    "actions": "Actions",
    "actor": "Actor",
    "add": "Add",
    "added": "Added",
    "admin": "Admin",
    "advanced": "Advanced",
    "all": "All",
    "allCountries": "All Countries",
    "allowed": "Allowed",
    "analytics": "Analytics",
    "athlosUserId": "Athlos User ID",
    "author": "author",
    "avatar": "Avatar",
    "ban": "Ban | Bans",
    "banned": "Banned",
    "base": "Base",
    "billing": "Billing",
    "bracket": "Bracket",
    "brackets": "Brackets",
    "bracketType": "Bracket type",
    "bye": "Bye",
    "cancel": "Cancel",
    "captain": "Captain",
    "categories": "categories",
    "category": "category",
    "change": "Change",
    "checkedIn": "Checked In",
    "chooseDate": "Choose Date",
    "clear": "Clear",
    "clone": "Clone",
    "close": "Close",
    "closeTime": "Close Time",
    "clubs": "Club | Clubs",
    "color": "color | colors",
    "compact": "Compact",
    "competition": "Competition",
    "configuration": "Configuration",
    "configurationPair": "Configuration Pair",
    "configure": "Configure",
    "confirm": "Confirm",
    "confirmDeletion": "Please confirm you want to delete this record",
    "contactAthlos": "Contact Athlos",
    "content": "content",
    "continue": "Continue",
    "copied": "Copied to clipboard",
    "copy": "Copy",
    "copyFailed": "Copy failed",
    "copySuccess": "Copied to clipboard",
    "countries": {
      "AD": "Andorra",
      "AE": "United Arab Emirates",
      "AF": "Afghanistan",
      "AG": "Antigua and Barbuda",
      "AI": "Anguilla",
      "AL": "Albania",
      "AM": "Armenia",
      "AO": "Angola",
      "AQ": "Antarctica",
      "AR": "Argentina",
      "AS": "American Samoa",
      "AT": "Austria",
      "AU": "Australia",
      "AW": "Aruba",
      "AX": "Åland Islands",
      "AZ": "Azerbaijan",
      "BA": "Bosnia and Herzegovina",
      "BB": "Barbados",
      "BD": "Bangladesh",
      "BE": "Belgium",
      "BF": "Burkina Faso",
      "BG": "Bulgaria",
      "BH": "Bahrain",
      "BI": "Burundi",
      "BJ": "Benin",
      "BM": "Bermuda",
      "BN": "Brunei Darussalam",
      "BO": "Bolivia, Plurinational State of",
      "BQ": "Bonaire, Sint Eustatius and Saba",
      "BR": "Brazil",
      "BS": "Bahamas",
      "BT": "Bhutan",
      "BV": "Bouvet Island",
      "BW": "Botswana",
      "BY": "Belarus",
      "BZ": "Belize",
      "CA": "Canada",
      "CC": "Cocos (Keeling) Islands",
      "CD": "Congo, the Democratic Republic of the",
      "CF": "Central African Republic",
      "CG": "Congo",
      "CH": "Switzerland",
      "CI": "Côte d'Ivoire",
      "CK": "Cook Islands",
      "CL": "Chile",
      "CM": "Cameroon",
      "CN": "China",
      "CO": "Colombia",
      "CR": "Costa Rica",
      "CU": "Cuba",
      "CV": "Cape Verde",
      "CW": "Curaçao",
      "CX": "Christmas Island",
      "CY": "Cyprus",
      "CZ": "Czech Republic",
      "DE": "Germany",
      "DJ": "Djibouti",
      "DK": "Denmark",
      "DM": "Dominica",
      "DO": "Dominican Republic",
      "DZ": "Algeria",
      "EC": "Ecuador",
      "EE": "Estonia",
      "EG": "Egypt",
      "ER": "Eritrea",
      "ES": "Spain",
      "ET": "Ethiopia",
      "FI": "Finland",
      "FJ": "Fiji",
      "FK": "Falkland Islands (Malvinas)",
      "FM": "Micronesia",
      "FO": "Faroe Islands",
      "FR": "France",
      "GA": "Gabon",
      "GB": "United Kingdom",
      "GD": "Grenada",
      "GE": "Georgia",
      "GF": "French Guiana",
      "GG": "Guernsey",
      "GH": "Ghana",
      "GI": "Gibraltar",
      "GL": "Greenland",
      "GM": "Gambia",
      "GN": "Guinea",
      "GP": "Guadeloupe",
      "GQ": "Equatorial Guinea",
      "GR": "Greece",
      "GT": "Guatemala",
      "GU": "Guam",
      "GW": "Guinea-Bissau",
      "GY": "Guyana",
      "HN": "Honduras",
      "HR": "Croatia",
      "HT": "Haiti",
      "HU": "Hungary",
      "ID": "Indonesia",
      "IE": "Ireland",
      "IL": "Israel",
      "IN": "India",
      "IO": "British Indian Ocean Territory",
      "IQ": "Iraq",
      "IR": "Iran",
      "IS": "Iceland",
      "IT": "Italy",
      "JM": "Jamaica",
      "JO": "Jordan",
      "JP": "Japan",
      "KE": "Kenya",
      "KG": "Kyrgyzstan",
      "KH": "Cambodia",
      "KI": "Kiribati",
      "KM": "Comoros",
      "KN": "Saint Kitts and Nevis",
      "KP": "North Korea",
      "KW": "Kuwait",
      "KY": "Cayman Islands",
      "KZ": "Kazakhstan",
      "LA": "Laos",
      "LB": "Lebanon",
      "LC": "Saint Lucia",
      "LI": "Liechtenstein",
      "LK": "Sri Lanka",
      "LR": "Liberia",
      "LS": "Lesotho",
      "LT": "Lithuania",
      "LU": "Luxembourg",
      "LV": "Latvia",
      "LY": "Libya",
      "MA": "Morocco",
      "MC": "Monaco",
      "MD": "Moldova",
      "ME": "Montenegro",
      "MG": "Madagascar",
      "MH": "Marshall Islands",
      "MK": "North Macedonia",
      "ML": "Mali",
      "MM": "Myanmar (Burma)",
      "MN": "Mongolia",
      "MR": "Mauritania",
      "MT": "Malta",
      "MU": "Mauritius",
      "MV": "Maldives",
      "MW": "Malawi",
      "MX": "Mexico",
      "MY": "Malaysia",
      "MZ": "Mozambique",
      "NA": "Namibia",
      "NE": "Niger",
      "NG": "Nigeria",
      "NI": "Nicaragua",
      "NL": "Netherlands",
      "NO": "Norway",
      "NP": "Nepal",
      "NR": "Nauru",
      "NZ": "New Zealand",
      "OM": "Oman",
      "PA": "Panama",
      "PE": "Peru",
      "PF": "French Polynesia",
      "PG": "Papua New Guinea",
      "PH": "Philippines",
      "PK": "Pakistan",
      "PL": "Poland",
      "PS": "Palestine",
      "PT": "Portugal",
      "PW": "Palau",
      "PY": "Paraguay",
      "QA": "Qatar",
      "RO": "Romania",
      "RS": "Serbia",
      "RU": "Russia",
      "RW": "Rwanda",
      "SA": "Saudi Arabia",
      "SB": "Solomon Islands",
      "SC": "Seychelles",
      "SD": "Sudan",
      "SE": "Sweden",
      "SG": "Singapore",
      "SI": "Slovenia",
      "SK": "Slovakia",
      "SL": "Sierra Leone",
      "SM": "San Marino",
      "SN": "Senegal",
      "SO": "Somalia",
      "SR": "Suriname",
      "ST": "Sao Tome and Principe",
      "SV": "El Salvador",
      "SY": "Syria",
      "SZ": "Swaziland",
      "TD": "Chad",
      "TF": "French Southern Territories",
      "TG": "Togo",
      "TH": "Thailand",
      "TJ": "Tajikistan",
      "TM": "Turkmenistan",
      "TN": "Tunisia",
      "TO": "Tonga",
      "TR": "Turkey",
      "TT": "Trinidad and Tobago",
      "TV": "Tuvalu",
      "TW": "Taiwan",
      "TZ": "Tanzania",
      "UA": "Ukraine",
      "UG": "Uganda",
      "US": "United States",
      "UY": "Uruguay",
      "UZ": "Uzbekistan",
      "VA": "Vatican City",
      "VC": "Saint Vincent and the Grenadines",
      "VE": "Venezuela",
      "VN": "Vietnam",
      "VU": "Vanuatu",
      "WS": "Samoa",
      "YE": "Yemen",
      "ZA": "South Africa",
      "ZM": "Zambia",
      "ZW": "Zimbabwe"
    },
    "country": "Country",
    "create": "Create",
    "createdAt": "Created at",
    "createdBy": "Created By",
    "currency": {
      "usd": "USD"
    },
    "custom": "custom",
    "date": {
      "default": "date",
      "end": "End Date",
      "start": "Start Date"
    },
    "debug": "Debug",
    "debugging": "Debugging",
    "default": "Default",
    "delete": "delete",
    "deleteAll": "delete all",
    "description": "description",
    "devTools": "Dev Tools",
    "disabled": "Disabled",
    "displayMode": "Display Mode",
    "docs": "Docs",
    "download": "Download",
    "duplicate": "Duplicate Entry",
    "edit": "Edit",
    "editor": "Editor",
    "eliminationType": "Elimination Type",
    "email": "email",
    "emailAddress": "Email Address",
    "enabled": "Enabled",
    "enterTextHere": "Enter text here...",
    "error": "Error",
    "event": "event | events",
    "events": "Events",
    "eventsAndPrograms": "Events & Programs",
    "eventType": "Event type | Event types",
    "expiresAt": "Expires at",
    "externalId": "External ID",
    "externalUserId": "External User ID",
    "failed": "Failed",
    "false": "false",
    "filter": "Filter | Filters",
    "finalScore": "Final Score",
    "finished": "Finished",
    "finishedAt": "Finished at",
    "finishTime": "Finish time",
    "fonts": "Fonts",
    "force": "Force",
    "form": "Form",
    "formNotValid": "Form data is not valid, check your data before submit",
    "from": "from",
    "fromDate": "From date",
    "game": "Game",
    "games": "Games",
    "generate": "Generate",
    "generated": "Generated",
    "goBack": "Go back",
    "hide": "Hide",
    "history": "History",
    "home": "Home",
    "id": "ID",
    "image": "image",
    "images": "Images",
    "imageUrl": "Image URL",
    "incomplete": "Incomplete",
    "ip": "IP",
    "item": "item",
    "items": "items",
    "jobs": "Jobs",
    "joined": "Joined",
    "json-editor": "JSON Editor",
    "jumpTo": "Jump to id",
    "key": "Key | Keys",
    "languages": "Languages",
    "languagesAndLocalization": "Languages & Localization",
    "leaderboard": "Leaderboard | Leaderboards",
    "link": "Link",
    "live": "Live",
    "liveStream": "Live Stream | Live Streams",
    "loading": "Loading",
    "locationMapper": "Location mapper",
    "log": "Log | Logs",
    "logout": "Logout",
    "loss": "Loss | Losses",
    "manage": "Manage",
    "match": "Match",
    "matches": "Matches",
    "matchup": "Matchup",
    "max": "Max",
    "member": "Member",
    "message": "Message",
    "min": "Min",
    "minRound": "Start Round",
    "mins": "mins",
    "minute": "Minute | Minutes",
    "myGames": "My games",
    "name": "name",
    "nameES": "name (es)",
    "namePT": "name (pt-PT)",
    "naming": "Naming",
    "net": "Net",
    "network": "Network",
    "new": "New",
    "no": "No",
    "noData": "No Data",
    "notAllowed": "Not Allowed",
    "notAvailable": "N/A",
    "note": "note",
    "notes": "notes",
    "notifications": "Notifications",
    "notReady": "Not Ready",
    "numberOfRosters": "Number of rosters",
    "occurrences": "Occurrences",
    "off": "Off",
    "ok": "OK",
    "on": "On",
    "onlyPngJpg": "Only upload picture files in png / jpg format, please re-upload.",
    "open": "Open time",
    "order": "Order",
    "organization": "Organization",
    "otherLanguages": "Other Languages",
    "overview": "Overview",
    "owner": "Owner",
    "ownerId": "@:common.owner Id",
    "page": "page",
    "panel": "Admin panel",
    "participants": "Participants",
    "password": "password",
    "payments": "Payment | Payments",
    "periods": "Period | Periods",
    "permission": "permission | permissions",
    "permissions": "permissions",
    "picked": "Picked",
    "player": "Player",
    "players": "Players",
    "predefined": "predefined",
    "preferences": "Preferences",
    "prefix": "Prefix",
    "preview": "Preview",
    "price": "Price",
    "privacy": "Privacy",
    "products": "Product | Products",
    "provider": "Provider",
    "queue": "Queue",
    "rawClose": "Close",
    "rawData": "Raw data",
    "rawOpen": "Open",
    "readOnly": "Read only",
    "ready": "Ready",
    "redirecting": "Redirecting",
    "refreshData": "Refresh Data",
    "registrationType": "Registration",
    "reminder": "reminder | reminders",
    "removed": "Removed",
    "replace": "Replace",
    "replay": "Replay",
    "request": "Request",
    "required": "Required",
    "reset": "reset",
    "role": "Role | Roles",
    "roles": "Roles",
    "rosterCount": "Roster Count",
    "rosters": "Roster | Rosters",
    "rostersGeneration": "Rosters generation",
    "rosterStatus": "roster status",
    "round": "Round {0}",
    "roundNumber": "Round number",
    "roundRobin": "Round Robin",
    "save": "Save",
    "schedule": "Schedule",
    "scheduled": "Scheduled",
    "search": "Search",
    "second": "Second | Seconds",
    "security": "Security",
    "segment": "segment | segments",
    "segments": "Segments",
    "select": "select",
    "selected": "Selected",
    "selectFile": "Select File",
    "selectImage": "Select Image",
    "send": "send",
    "service": "service",
    "settings": "Settings",
    "show": "Show",
    "showAll": "Show all",
    "sku": "SKU",
    "slug": "slug",
    "sms": "SMS",
    "source": "source",
    "standard": "Standard",
    "standby": "Stand By",
    "standings": "Standings",
    "startedAt": "Started at",
    "state": "state",
    "statistics": "Statistics",
    "status": {
      "failed": "Failed",
      "finished": "Finished",
      "title": "Status"
    },
    "subject": "Subject",
    "submit": "submit",
    "subscribedAt": "Subscribed at",
    "success": "Success",
    "support": "support",
    "suspend": "suspend",
    "swiss": "Swiss",
    "tag": "Tag",
    "target": "Target",
    "team": "Team | Teams",
    "teamsAndClubs": "Teams & Clubs",
    "template": "Template | Templates",
    "templateType": "Template Type",
    "tenant": "tenant",
    "the": "The",
    "theming": "Theming",
    "time": "Time",
    "timezone": "Timezone",
    "title": "Title",
    "to": "to",
    "toDate": "To date",
    "total": "Total",
    "totalResults": "Total Results",
    "trigger": "trigger",
    "true": "true",
    "type": "Type",
    "unknownError": "Unknown error occurred",
    "update": "update",
    "upload": {
      "default": "Upload",
      "success": "Upload successful"
    },
    "url": "Url",
    "urlNotValid": "URL not valid",
    "user": "User",
    "userAgent": "User Agent",
    "userId": "User ID",
    "username": "Username",
    "users": "Users",
    "value": "Value",
    "viewAll": "view all",
    "webhook": "Webhook | Webhooks",
    "welcome": "Welcome to Athlos",
    "whitelist": "Whitelist",
    "win": "Win | Wins",
    "yes": "Yes"
  },
  "configuration": {
    "color": "Please select a color",
    "number": "Please enter a number",
    "value": "Please enter a value"
  },
  "delete": {
    "partialError": "Deletion request could not be entirely completed. Try one by one to get detailed errors."
  },
  "error": {
    "404": "404 Not Found",
    "greaterThanOrEqualsTwo": "Value must be greater than or equal to 2",
    "greaterThanOrEqualsZero": "Value must be greater than or equals to zero",
    "greaterThanZero": "Value must be greater than 0",
    "invalidChannelUrl": "Channel Url must be a valid Youtube or Twitch url",
    "noAdminPermissions": "No admin permissions found for this user",
    "players": {
      "maxNumMustBeGreaterThanMinNum": "Maximum number of players must be greater than minimum number of players"
    },
    "required": "Required.",
    "resourseNotFound": "Resource not found",
    "segment": {
      "matchSeriesMaxRosterSizeUndefined": "Match Series max roster size must be defined",
      "matchSeriesMinRosterSizeUndefined": "Match Series min roster size must be defined",
      "maxRosterSizedUndefined": "Max Roster Size must be defined.",
      "minRosterSizeUndefined": "Segment Min Roster Size must be defined.",
      "valueGreaterThanOrEqualMatchSeriesMinRosterSize": "Value must be greater or equal than the match series min roster size",
      "valueMustBeGreaterThanOrEqualsMinRosterSize": "Value must be greater than or equals to segment min roster size",
      "valueMustBeLessThanOrEqualsMaxRosterSize": "Value must be less than or equals to segment max roster size",
      "valueMustBeSmallerOrEqualMatchSeriesMaxRosterSize": "Value must be smaller or equal than the match series max roster size"
    }
  },
  "event": {
    "series": "Event series",
    "title": "Event title"
  },
  "events": {
    "about": {
      "description": "About Event Section Description (HTML allowed)",
      "languageSelect": "Select text language",
      "localization": "Event specific text",
      "title": "About Event Section Title"
    },
    "clone": {
      "confirm": "Are you sure about cloning this event?|Are you sure about cloning these events?",
      "failed": "An error occurred while cloning the event(s)",
      "success": "Event(s) cloned successfully"
    },
    "create": {
      "failed": "An error occurred while creating the event",
      "success": "Event created successfully"
    },
    "delete": {
      "confirm": "Are you sure about deleting this event?|Are you sure about deleting these events?",
      "failed": "An error occurred while deleting the event(s)",
      "success": "Event(s) deleted successfully"
    },
    "edit": {
      "failed": "An error occurred while updating the event",
      "success": "Event updated successfully"
    },
    "form": {
      "eventDates": "Event Dates"
    },
    "headerImageUpload": {
      "proportion": "proportion",
      "title": "Header Image (jpg, jpeg, png, svg, webp)"
    },
    "headerImageUploaded": "Current 16:9 Header Image",
    "segments": "Event segments",
    "tabletHeaderImageUploaded": "Current 6:1 Header Image"
  },
  "externalId": {
    "delete": {
      "message": "Please confirm the deletion of the selected external ids.",
      "success": "External Ids deleted successfully"
    }
  },
  "footer": {
    "gdpr": "GDPR",
    "withdraw": "Withdraw from the competition"
  },
  "game": {
    "create": {
      "failed": "An error occurred while creating the game",
      "success": "Game created successfully"
    },
    "delete": {
      "failed": "An error occurred while deleting the game(s)",
      "success": "Game(s) deleted successfully"
    },
    "edit": {
      "failed": "An error occurred while updating the game",
      "success": "Game updated successfully"
    },
    "upload": {
      "description": "of the game"
    }
  },
  "gameAccounts": {
    "delete": {
      "success": "Game accounts deleted successfully."
    }
  },
  "getStarted": {
    "guide": "Get started now with our guide",
    "howToCompete": "How to compete",
    "totalPrize": "Total prize pool"
  },
  "hasAdminPermissions": "Has admin permissions",
  "header": {
    "compete": "You're competing in Season 1's Monthly Qualifier!",
    "EndsIn": "Ends In",
    "letsGo": "Let's Go",
    "tournamentNews": "Tournament News"
  },
  "home": {
    "athlosReadme": "Athlos Readme",
    "createFirstEvent": "Create your first event",
    "noDataYet": "No data yet",
    "noEventsYet": "No events yet"
  },
  "inapp": {
    "fonts": {
      "copy": "Copy link",
      "deleted": "Font deleted successfully",
      "error": {
        "file": "The font file is required, please choose a ttf, otf, woff or woff2 file.",
        "format": "Font format not allowed, please choose a ttf, otf, woff or woff2 file."
      },
      "fileName": "File name",
      "fontFile": "Font File",
      "list": "Fonts list",
      "success": "Font uploaded successfully",
      "type": "Font Type"
    },
    "images": {
      "companyLogo": "Company Logo",
      "homeHeader16": "Home Header 16:9",
      "homeHeader61": "Home Header 6:1"
    }
  },
  "input": {
    "placeholder": {
      "default": "Please input",
      "userId": "Input User ID",
      "userOrRoster": "Input Username / Roster name"
    }
  },
  "job": {
    "failureMessage": "Failure Message",
    "finished": {
      "matches": {
        "finished": {
          "error": "Job {name} finished with errors",
          "success": "Job {name} finished successfully"
        },
        "nextRoundGeneration": "Next Round Generation"
      }
    },
    "matches": {
      "bracketDestroy": "Bracket destroy",
      "bracketGeneration": "Bracket generation",
      "seedMatches": {
        "randomly": "Seed Matches Randomly"
      }
    }
  },
  "jumpTo": {
    "invalidId": "Please input a valid ID"
  },
  "keys": {
    "create": {
      "failed": "An error occurred while creating the key",
      "success": "Key created successfully"
    },
    "delete": {
      "failed": "An error occurred while deleting the key(s)",
      "success": "Key(s) deleted successfully"
    },
    "edit": {
      "failed": "An error occurred while updating the key",
      "success": "Key updated successfully"
    },
    "load": {
      "failed": "An error occurred while loading the keys"
    },
    "new": {
      "privateKeyNotice": "Please copy this private key to somewhere safe. You won't be able to view it again after you close this dialog",
      "success": "Your new authentication key"
    }
  },
  "languages": {
    "es": "Spanish",
    "pt-PT": "Portuguese (Portugal)"
  },
  "languagesLocalization": {
    "noLanguageFound": "No language found, select at least one language to change custom translations",
    "successfullyReset": "Language/Localization successfully reset",
    "successfullyUpdated": "Language/Localization successfully updated",
    "supportedLanguages": "Supported Languages",
    "translations": "Translations"
  },
  "leaderboards": {
    "add": {
      "segments": "Segment(s) added successfully to @:common.leaderboard"
    },
    "addSegment": "Add segments",
    "adjustments": {
      "create": "Create Leaderboard Adjustments",
      "success": "Leaderboard Adjustments created successfully"
    },
    "create": "Create Leaderboards",
    "deleteSegment": "Delete segment(s)",
    "entries": "Leaderboard Entries",
    "error": {
      "noId": "No leaderboard id provided"
    },
    "isAutoRecalculationEnabled": "Enable Auto Recalculation",
    "notFound": "No leaderboards found",
    "participantSource": "Participant Source",
    "remove": {
      "segments": "Segment(s) removed successfully from @:common.leaderboard"
    },
    "save": {
      "error": "Error while saving leaderboards",
      "success": "Leaderboards saved successfully"
    }
  },
  "liveStreams": {
    "create": {
      "success": "Live stream created successfully."
    },
    "delete": {
      "message": "Please confirm the deletion of the selected live streams.",
      "success": "Live streams deleted successfully."
    },
    "edit": {
      "success": "Live stream updated successfully."
    },
    "timeSlots": {
      "create": "Time slots created successfully.",
      "delete": "Time slots deleted successfully."
    }
  },
  "login": {
    "backToLogin": "Back to login",
    "changePassword": {
      "title": "Set your new password",
      "title1": "Set your",
      "title2": "new password"
    },
    "conflictTenants": "There were more than one admin account found for the given email address. Please specify a tenant and try again.",
    "forgotPassword": "Forgot your password?",
    "noTenants": "No tenants found",
    "noTenantSelected": "No tenant selected",
    "resetPassword": {
      "title": "Reset your Password",
      "title1": "Reset your",
      "title2": "Password"
    },
    "selectTenant": "Select one Tenant",
    "success": "Login successful",
    "title": "Log in to Athlos",
    "title1": "log in to",
    "title2": "Athlos universal admin panel"
  },
  "match": {
    "allowRepeatedPlayers": "Allow repeated players",
    "autoForfeit": {
      "confirm": "Are you sure you want to forfeit this match?",
      "title": "Auto forfeit"
    },
    "bulkForfeit": {
      "success": "Bulk match forfeit succeeded",
      "title": "Bulk match forfeit"
    },
    "chat": {
      "title": "Chat",
      "type": "Type a message..."
    },
    "checkin": {
      "confirm": "Are you sure checking-in this user?",
      "details": "Check-in details",
      "performedBy": {
        "all": "All",
        "owner": "Owner",
        "title": "Performed by"
      },
      "success": "User checked-in successfully",
      "title": "User Check-in"
    },
    "checkinDate": "Checkin date",
    "checkins": "Checkins",
    "create": {
      "failed": "An error occurred while creating the match",
      "success": "Match created successfully"
    },
    "date": "Match date",
    "delete": {
      "confirm": "Are you sure deleting this match?",
      "failed": "An error occurred while deleting the match(es)",
      "success": "Match(s) deleted successfully",
      "title": "Delete Match"
    },
    "deleteData": "Delete external match",
    "details": "Match details",
    "disputes": "Disputes",
    "doubleDQ": "Double DQ",
    "edit": {
      "failed": "An error occurred while updating the match",
      "success": "Match updated successfully"
    },
    "evidence": "Evidence",
    "externalData": "External match data",
    "forfeit": "Forfeit win",
    "forfeitWinnerRequired": "A winner by forfeit is required",
    "forFirstMatch": "for the 1st match",
    "group": "Group",
    "hasJoined": "Has joined",
    "hasRosterAssigned": "Has roster assigned",
    "ip": "IP Address",
    "isInBattle": "In battle",
    "isOnline": "Is online",
    "isReady": "Is ready",
    "isTie": "Is tie breaker",
    "log": "Activity log",
    "matchCheckins": "Match checkins",
    "matchSeriesCheckins": "Match series checkins",
    "matchUpdateInformation": {
      "success": "Match updated successfully",
      "wait": "Updating Scores"
    },
    "noMatchesGenerated": "You must wait until matches has been generated until define match dates",
    "number": "Match number",
    "playerSelection": {
      "allowUpdate": "Allow player selection update after first select",
      "title": "Player selection"
    },
    "replacePlayer": "Replace Player",
    "replacePlayerErrorMessage": "You can not replace players from played set",
    "replacePlayerForMatch": "Replace Player for Match {matchNumber}",
    "replacePlayersError": "An error occurred when replace players",
    "replacePlayerSuccess": "Players replaced successfully",
    "replacePlayerWarningMessage": "Current Set will be cancelled after replace a player",
    "replay": {
      "failed": "An error occurred while resetting the match(es)",
      "success": "Match reset successfully",
      "title": "Reset external match"
    },
    "reportByScorePeriod": "Report by score period",
    "reset": {
      "confirm": "Are you sure you want to reset the match?",
      "error": "Error resetting match series",
      "success": "Match series has been fully reset successfully",
      "title": "Reset Match"
    },
    "resetScore": {
      "confirm": "Are you sure you want to reset the score?",
      "success": "Match score has been cleared successfully",
      "title": "Reset score"
    },
    "roster": "Roster",
    "rosters": "Rosters",
    "score": "Match score",
    "scorePeriod": "Score Period",
    "scorePeriodRequired": "Not all score periods that are required are filled in",
    "select": "Select a match",
    "series": "Match series",
    "set": "Set",
    "setAutoForfeitByMatchSeries": {
      "success": "Auto forfeit by match series succeeded",
      "title": "Forteit Matches"
    },
    "setAutoForfeitBySegment": {
      "success": "Auto forfeit by segment succeeded",
      "title": "Forfeit Segment"
    },
    "setDate": {
      "success": "Match Date has been changed successfully",
      "title": "Set Date"
    },
    "setDateEmpty": "You must define all dates before save",
    "setDateForMatch": "Set Date for Match {matchNumber}",
    "setNumber": "Set Number",
    "setRoundTime": {
      "amountMatches": "For All {matchNumber} Matches in Round {roundNumber}",
      "error": "An error occurred when setting round time",
      "missingDates": "Missing dates for matches",
      "missingParams": "Missing parameters segments/round number",
      "noMatchForRound": "No matches found for this round",
      "success": "Round time set successfully",
      "title": "Set round time"
    },
    "stage": "Stage / Round",
    "startingTimeFirstMatch": "Starting Time",
    "status": {
      "finished": "Finished",
      "live": "Live",
      "new": "New",
      "scheduled": "Scheduled"
    },
    "submitScore": {
      "invalidData": "You need to fill all required fields first",
      "notChanged": "You can not submit the same score again",
      "success": "Score submitted successfully",
      "title": "Submit score"
    },
    "success": "Match series has been fully reset successfully",
    "time": "Match time",
    "timeWindow": "Time window",
    "toAutoForfeit": "To auto forfeit",
    "uploadEvidence": {
      "picture": "Upload a picture"
    },
    "username": "Username",
    "winnerByForfeit": "Winner by forfeit"
  },
  "matches": {
    "live": "Live",
    "message": {
      "delete": {
        "success": "Match Series delete successfully"
      },
      "empty": "No messages available"
    },
    "tbd": "TBD",
    "upcoming": "Upcoming Match"
  },
  "matchSeries": {
    "delete": {
      "message": "Please confirm the deletion of the selected match series"
    },
    "deleteAll": {
      "message": "Please confirm the deletion of the entire segment's matches."
    }
  },
  "notes": {
    "create": "create note",
    "delete": {
      "success": "Notes deleted successfully."
    },
    "success": "Note created successfully"
  },
  "notifications": {
    "replyTo": "reply to",
    "segmentReminders": {
      "create": {
        "success": "Segment reminder created successfully",
        "title": "create segment reminder"
      }
    },
    "sender": "sender",
    "templates": {
      "edit": {
        "success": "Template updated successfully",
        "title": "edit notification template"
      },
      "external": "external template",
      "sendAdhocMessage": {
        "noUsersSelected": "No users selected",
        "success": "Message sent successfully",
        "title": "send ad-hoc message"
      },
      "sendPreview": {
        "success": "Template preview sent successfully",
        "title": "send preview"
      },
      "title": "Notification Templates"
    },
    "timeThreshold": "time threshold (s)",
    "triggers": {
      "segment-start-date": "segment start date"
    }
  },
  "overview": {
    "errorCopyId": "An error occurred while copying the Id",
    "successCopyId": "The Id has been copied successfully"
  },
  "passwordReset": {
    "success": "We sent a link to your email to reset your password. Please click on that link to continue"
  },
  "payments": {
    "analytics": {
      "averageTransactionSize": "Average Transaction Size",
      "averageTransactionSizeByCountry": "Avg. Transaction Size by Country",
      "grossRevenue": "Gross Revenue",
      "grossRevenueByCountry": "Gross Revenue by Country",
      "grossRevenueByPaymentMethod": "Gross Revenue by Payment Method",
      "incompletePayments": "Incomplete Payments",
      "payouts": "Payouts",
      "successfulPayments": "Successful Payments",
      "successfulPaymentsByCountry": "Successful Payments by Country",
      "successfulPaymentsByPaymentMethod": "Successful Payments by Payment Method"
    },
    "avgTransactionsSize": "Avg Transaction Size",
    "completedPayouts": "Completed Payouts",
    "completedTransactions": "Completed Purchases",
    "grossRevenue": "Gross Revenue",
    "overview": {
      "past24hours": "Past 24 hours"
    },
    "products": {
      "amountGraterThanZero": "Amount needs to be greater than zero",
      "create": {
        "failed": "An error occurred while creating the product",
        "success": "Product created successfully",
        "warning": "Please note new products may not immediately appear in the player experience as they are synced with our 3rd party gateway."
      },
      "defaultChoice": {
        "failed": "An error occurred while updating the default choice",
        "success": "Default choice updated successfully",
        "title": "Default Choice",
        "tooltip": "This is the product that will be selected by default in the player experience."
      },
      "delete": {
        "confirm": "Are you sure about deleting this product?|Are you sure about deleting these products?",
        "failed": "An error occurred while deleting the product(s)",
        "success": "Product(s) deleted successfully"
      },
      "edit": {
        "failed": "An error occurred while updating the product",
        "success": "Product updated successfully"
      },
      "featuredProducts": {
        "failed": "An error occurred while updating the featured products",
        "limit": "You can have up to 6 featured products at a time.",
        "success": "Featured products updated successfully",
        "title": "Featured Products"
      },
      "partnerSync": {
        "disabled": "Unsynced",
        "enabled": "Synced"
      },
      "purchasePrice": "Purchase Price",
      "virtualValue": "Virtual Value"
    },
    "totalValue": "Total Value",
    "transactionId": "Transaction ID",
    "transactions": {
      "net": {
        "tooltip": "Net = Price minus (Gateway + Athlos Fees)"
      },
      "title": "Transaction | Transactions"
    },
    "transactionStatus": {
      "completed": "Completed",
      "pending": "Pending",
      "refused": "Refused",
      "started": "Started"
    },
    "transactionType": {
      "deposit": "Purchase",
      "payment": "Payment",
      "prize": "Prize",
      "refund": "Charge Back",
      "title": "Transaction Type",
      "withdraw": "Withdraw"
    }
  },
  "pending_activation": "pending activation",
  "permissions": {
    "adminPermissions": "admin permissions",
    "grant": "grant",
    "label": "Permissions",
    "revoke": "revoke",
    "update": {
      "grant": {
        "message": "Please confirm granting admin permissions to the selected user."
      },
      "revoke": {
        "message": "Please confirm revoking admin permissions to the selected user."
      },
      "success": "Permissions updated successfully",
      "title": "Update permissions"
    }
  },
  "phases": {
    "lastChance": "Last chance qualifier",
    "monthly": "Monthly qualifier",
    "monthlyFinal": "Monthly final",
    "trophyRace": "Trophy race",
    "worldFinals": "World finals"
  },
  "placeholder": {
    "seedingOptions": "There is currently no seeding options"
  },
  "player": {
    "details": "Player details"
  },
  "queue": {
    "eloPoints": "Elo Points",
    "joinedQueueTimesInLast24h": "Queue Join Count (in 24 hours)",
    "matchUpsCountInLast24h": "Match Ups Join Count (in 24 hours)"
  },
  "registrationPhase": {
    "create": {
      "failed": "An error occurred while creating the registration phase",
      "success": "Registration phase created successfully"
    },
    "delete": {
      "failed": "An error occurred while deleting the registration phase(s)",
      "success": "Registration phase(s) deleted successfully"
    },
    "edit": {
      "failed": "An error occurred while updating the registration phase",
      "success": "Registration phase updated successfully"
    },
    "not_found": "Registration phase not found"
  },
  "reward": {
    "final": "Advance to Final"
  },
  "roles": {
    "assignRoles": "Assign Roles",
    "create": {
      "success": "Role created successfully",
      "title": "create role"
    },
    "deleted": "Role deleted successfully",
    "edit": {
      "success": "Role updated successfully",
      "title": "update role"
    }
  },
  "rosters": {
    "addPlayer": {
      "success": "Player added successfully",
      "title": "Add Player"
    },
    "advancement": {
      "failed": "Advancement failed, destination segment and advanced rosters are required to perform this action",
      "success": "Roster(s) advanced successfully"
    },
    "checkin": {
      "bulk": {
        "error": "Not all rosters could be checked in, the remaining ones are still selected."
      },
      "confirm": "Please confirm the manual checkin of the selected participants",
      "success": "Roster(s) checked-in successfully",
      "title": "checkin"
    },
    "create": {
      "success": "Roster created successfully",
      "title": "create roster"
    },
    "delete": {
      "success": "@:common.roster deleted successfully"
    },
    "destinationSegment": "Destination segment",
    "distribute": {
      "message": "Please confirm the random distribution of roster in child segments."
    },
    "edit": "edit roster",
    "editPlayer": {
      "success": "Player updated successfully"
    },
    "eloPointsRange": "elo points range",
    "empty": "No roster",
    "joinKey": "join key",
    "owner": {
      "change": {
        "error": "An error occurred while changing the owner",
        "success": "Owner changed successfully",
        "title": "Change Owner"
      }
    },
    "prefix": "Rosters prefix",
    "replacePlayer": {
      "selectPlayer": "You need to select a player first",
      "success": "Replaced player successfully",
      "title": "Replace Player"
    },
    "rosterId": "Roster ID",
    "rosterOverview": "roster overview",
    "status": {
      "checkedin": "checked in",
      "notCheckedin": "not checked in"
    }
  },
  "schedule": {
    "allGroups": "all groups"
  },
  "segment": {
    "addRosters": {
      "success": "Roster(s) added successfully",
      "title": "add rosters"
    },
    "advance": {
      "failed": "An error occurred while trying to trigger advancement rules",
      "success": "Triggered advancement rules successfully"
    },
    "advancedSegmentConfiguration": "Advanced Segment Configuration",
    "advancedSegmentEnable": {
      "title": "Enable Advanced Segment Configuration",
      "warning": "Enable advanced segment configuration to use the following options"
    },
    "advancement": {
      "trigger": {
        "confirm": "Please confirm the advancement of the segment."
      }
    },
    "advancementRules": "Advancement Rules",
    "allowedPlayers": "Allowed Players",
    "bracketSize": "Bracket size",
    "checkedInRosters": "Checked-in Rosters",
    "checkins": "Checkins",
    "coachesMax": "Coaches Max",
    "commaSpaced": "Comma spaced",
    "competition": {
      "eliminated": "Eliminated",
      "qualified": "Qualified",
      "title": "Competition"
    },
    "config": {
      "cadence": {
        "concurrent": "Concurrent",
        "manual": "Manual",
        "sequential": "Sequential"
      },
      "gapBetweenMatches": "Gap Between Matches",
      "matchScheduling": "Match Scheduling",
      "matchSeries": "Match series configuration",
      "singleMatch": "Single match configuration",
      "validation": "Please fix the errors in the segment configuration"
    },
    "configKeys": "Configuration Keys",
    "configuration": {
      "closeBeforeStart": "Close Before Start",
      "openBeforeStart": "Open Before Start"
    },
    "configurations": "Configurations",
    "create": {
      "failed": "An error occurred while creating the segment",
      "success": "Segment created successfully"
    },
    "delete": {
      "failed": "An error occurred while deleting the segment(s)",
      "success": "Segment(s) deleted successfully"
    },
    "deleteImage": {
      "success": "Segment image(s) deleted successfully"
    },
    "details": "Details",
    "disabledPlayerSelectionMessage": "Player Selection type can't be changed after matches have been generated.",
    "disableTiebreakMatch": "Disable Tie break Match",
    "distribute": {
      "success": "Rosters distributed successfully",
      "title": "distribute",
      "tooltip": "Distribute rosters randomly to child segments"
    },
    "edit": {
      "failed": "An error occurred while updating the segment",
      "success": "Segment updated successfully"
    },
    "eliminationType": "Elimination type",
    "entries": {
      "club": "Club",
      "isWinner": "Is winner",
      "participantGroup": "Participant group",
      "points": "Points"
    },
    "error": {
      "invalidMaxRosterSize": "Max size needs to be smaller than Roster Max Size.",
      "invalidMinRosterSize": "Min size needs to be greater than zero",
      "invalidRosterSize": "Min Size must be smaller or equal to Max Size.",
      "invalidTimeWindow": "Open time must be greater than Close time.",
      "jsonMutation": "Validating error! an error occurred while saving the JSON",
      "rosterSize": "Roster Size should be defined",
      "timeWindowSmallerThanZero": "Time window values must be greater than zero.",
      "undefinedRosterSize": "Min and Max size needs to be defined.",
      "undefinedTimeWindow": "Open and Close time must be defined."
    },
    "finalScore": "Final score",
    "forceAllowAdditionalRound": "Force additional round allowance",
    "format": "Format",
    "generateMatches": {
      "error": "An error occurred while generating matches for this segment",
      "job": "Job for Generate Matches has been created",
      "success": "Matches generated successfully",
      "title": "Generate matches"
    },
    "generateNextRound": {
      "error": "An error occurred while generating the next round",
      "job": "Job for Generate Next Round has been created",
      "success": "Round generated successfully",
      "title": "Generate next round"
    },
    "generation": {
      "failed": "An error occurred while generating the segments",
      "success": "Segments generated successfully"
    },
    "goToMatches": "Go to the list of matches",
    "image": "Segment Image",
    "isRequired": "Is Required",
    "iterationsNumber": "Iterations number",
    "keyConfiguration": "Key Configuration",
    "limit": "Limit",
    "lossesNumber": "Losses number",
    "manualAdvancements": "Manual advancements",
    "matches": {
      "autoForfeitByMatchSeries": {
        "message": "Please confirm the forfeiting of the selected match series."
      },
      "autoForfeitBySegment": {
        "message": "Please confirm the forfeiting of {num} matches."
      },
      "delete": {
        "job": "Job for Delete Matches has been created",
        "title": "Matches deleted successfully"
      },
      "generate": {
        "nextRound": {
          "message": "Please confirm the generation of matches for the next round."
        }
      },
      "reset": {
        "message": "Please confirm the resetting of the selected match series."
      }
    },
    "maxMissed": "Maximum Consecutive Missed Check-ins",
    "nextSegment": "Next segment",
    "nOfTimes": "Number of Times",
    "noMatchedNumbers": "The number of items in the custom text field should match the number of segments being generated",
    "numberOfSegments": "Number of Segments",
    "openRegistration": "Open Registration",
    "overview": "Overview",
    "participationLimits": "Participation limits",
    "placement": "Placement | Placements",
    "playersMax": "Players Max",
    "playersMin": "Players Min",
    "registrationPhase": "Registration Phase | Registration Phases",
    "registrationType": "Registration type",
    "reminders": {
      "delete": {
        "message": "Please confirm the deletion of the selected notifications."
      }
    },
    "restrictions": "Participation Restrictions",
    "rosterSize": "Roster size",
    "scoreperiod": {
      "create": {
        "failed": "An error occurred while trying to create the score period",
        "success": "Score period created successfully"
      },
      "delete": {
        "message": "Confirm the deletion of the selected score periods"
      },
      "edit": {
        "failed": "An error occurred while trying to edit the score period",
        "success": "Score period updated successfully"
      }
    },
    "scoreperiods": {
      "delete": {
        "failed": "An error occurred while deleting the score period(s)",
        "success": "Score period(s) deleted successfully"
      }
    },
    "scorePeriods": {
      "delete": {
        "failed": "An error occurred while deleting the score period(s)",
        "success": "Score period(s) deleted successfully"
      },
      "title": "Score Periods"
    },
    "seed": {
      "byLeaderboardPoints": "Seed by leaderboard points",
      "delete": "Delete bracket",
      "error": {
        "delete": "An error occurred while deleting the bracket",
        "multipleRosters": "A roster has been assigned multiple times",
        "noChanges": "No changes occurred",
        "title": "An error occurred while seeding the brackets"
      },
      "random": {
        "message": "Please confirm the random seeding of the following segment"
      },
      "randomly": "Seed randomly",
      "save": "Save seeding",
      "saveMessage": "Please confirm saving the selected seeding yo this segment.",
      "selectRoster": "Select a roster",
      "success": {
        "job": "Job for Seeding Bracket has been created.",
        "title": "Bracket seeded successfully."
      },
      "title": "Seed"
    },
    "seeding": "Seeding",
    "segmentCheckin": "Segment checkin",
    "segmentCheckinDate": "Segment checkin date",
    "segmentCheckinFrom": "Segment checkin from",
    "segmentCheckinTo": "Segment checkin to",
    "selectCsv": "Select CSV",
    "selectionTypes": {
      "all": "All",
      "manual": "Manual",
      "random": "Random"
    },
    "showClubSystem": "Show club system configuration",
    "sortOrder": "Sort order",
    "standBy": "Standby",
    "standings": {
      "recalculate": {
        "message": "Please confirm the manual recalculation of the standings",
        "success": "Recalculate standings successfully",
        "title": "Recalculate Standings"
      }
    },
    "stats": "Segment Stats",
    "triggerAdvancementRules": "Trigger Advancement Rules",
    "type": "Segment type",
    "upload": {
      "success": "Segment image uploaded successfully"
    },
    "whitelisting": "Whitelisting",
    "winnersThreshold": "Winners threshold",
    "winsNumber": "Wins number"
  },
  "segments": {
    "advancement": {
      "placementValidationError": "Placements must be comma separated numbers.",
      "undefinedId": "Rule ID is undefined"
    },
    "elimination": {
      "battleRoyale": "Battle Royale",
      "doubleElimination": "Double Elimination",
      "gsl": "GSL",
      "gslDoubleElimination": "GSL Double Elimination",
      "ladder": "Ladder",
      "ladderChallengeOriented": "Ladder (Challenge Oriented)",
      "ladderTimeOriented": "Ladder (Time Oriented)",
      "roundRobin": "Round Robin",
      "short": {
        "battleRoyale": "BR",
        "doubleElimination": "Double Elim",
        "gsl": "GSL",
        "gslDoubleElimination": "GSL Dbl Elim",
        "ladder": "Ladder",
        "ladderChallengeOriented": "Ladder (Challenge)",
        "ladderTimeOriented": "Ladder (time)",
        "roundRobin": "RR",
        "singleElimination": "Single Elim",
        "swiss": "Swiss",
        "swissLeaderboards": "Swiss (Leaderboard)"
      },
      "singleElimination": "Single Elimination",
      "swiss": "Swiss",
      "swissLeaderboards": "Swiss (Seeded by Leaderboards)"
    },
    "form": {
      "segmentCheckinDates": "Segment Checkin Date",
      "segmentDates": "Segment Dates"
    },
    "formats": {
      "bestOf1": "Best of 1",
      "bestOf3": "Best of 3",
      "bestOf5": "Best of 5",
      "bestOf7": "Best of 7",
      "short": {
        "bestOf1": "Bo1",
        "bestOf3": "Bo3",
        "bestOf5": "Bo5",
        "bestOf7": "Bo7"
      }
    },
    "registration": {
      "allowed": "Registration Allowed",
      "notAllowed": "Registration Not Allowed"
    },
    "scorePeriods": {
      "scoreIdNotDefined": "Score period id is not defined"
    }
  },
  "segmentType": {
    "create": {
      "failed": "An error occurred while creating the segment type",
      "success": "Segment type created successfully"
    },
    "delete": {
      "failed": "An error occurred while deleting the segment type(s)",
      "success": "Segment type(s) deleted successfully"
    },
    "edit": {
      "failed": "An error occurred while updating the segment type",
      "success": "Segment type updated successfully"
    },
    "error": {
      "noId": "Segment type id is not defined"
    },
    "title": "Segment Types"
  },
  "select": {
    "placeholder": {
      "event": "Select an event"
    }
  },
  "settings": {
    "reset": {
      "error": "An error occurred while resetting settings",
      "success": "Settings reset successfully"
    },
    "save": {
      "error": "An error occurred while saving settings",
      "success": "Settings saved successfully"
    }
  },
  "standings": {
    "lost": "Lost",
    "mp": "MP",
    "points": "Points",
    "position": "Position",
    "tied": "Tied",
    "won": "Won"
  },
  "stats": {
    "last30Days": "Last 30 Days"
  },
  "status": {
    "active": "active",
    "allowed": "allowed",
    "archived": "archived",
    "blocked_by_admin": "blocked by admin",
    "blockedByAdmin": "blocked by admin",
    "disqualified": "disqualified",
    "expired": "expired",
    "finished": "finished",
    "hasAdminPermissions": "Has admin permissions",
    "inactive": "inactive",
    "incomplete": "incomplete",
    "live": "live",
    "new": "new",
    "notAllowed": "not allowed",
    "pending_activation": "pending activation",
    "ready": "ready"
  },
  "stream": {
    "channelName": "Channel Name",
    "channelUrl": {
      "invalid": "Invalid channel URL format",
      "title": "Channel URL"
    },
    "hasActiveTimeSlot": "Has Active Timeslot",
    "language": "Language",
    "live": "Live stream",
    "timeSlot": {
      "current": "Current/Next timeslot",
      "delete": "Delete timeslots",
      "title": "Time slot"
    }
  },
  "supercell": {
    "externalData": {
      "delete": {
        "confirm": "Please confirm the deletion of the match external data",
        "success": "External data deleted successfully"
      },
      "reset": {
        "confirm": "Please confirm the reset of the match external data",
        "success": "Match External Data reseted successfully"
      }
    }
  },
  "swiss": {
    "noMatches": "No Matches"
  },
  "table": {
    "noData": "No Data",
    "totalResults": "Total Results:"
  },
  "theming": {
    "colors": {
      "resetSuccess": "Colors properties reset to default"
    },
    "dangerZone": {
      "confirmReset": "I confirm that I want to reset all of the theming properties to default",
      "resetDefault": "Reset to default",
      "resetSuccess": "Theming properties reset to default",
      "title": "Danger Zone"
    },
    "fonts": {
      "copy": "Copy link",
      "deleted": "Font deleted successfully",
      "error": {
        "file": "The font file is required, please choose a ttf, otf, woff or woff2 file.",
        "format": "Font format not allowed, please choose a ttf, otf, woff or woff2 file."
      },
      "fileName": "File name",
      "fontFile": "Font File",
      "list": "Fonts list",
      "resetSuccess": "Font properties reset to default",
      "success": "Font uploaded successfully",
      "type": "Font Type"
    },
    "terminology": {
      "feedbackErrorNothingToSave": "There is nothing to save, all fields are empty",
      "resetError": "An error occurred while resetting terminology properties",
      "resetSuccess": "Terminology properties reset to default",
      "title": "Terminology",
      "yourGame": "Your Game"
    },
    "welcomeScreen": {
      "images": {
        "companyLogo": "Company Logo",
        "homeHeader16": "Home Header 16:9",
        "homeHeader61": "Home Header 6:1"
      },
      "resetError": "An error occurred while resetting welcome screen properties",
      "resetSuccess": "Welcome Screen properties reset to default",
      "title": "Landing/Welcome Screen",
      "welcomeMessage": "Welcome Message",
      "welcomeSubtext": "Welcome Subtext"
    }
  },
  "transfer": {
    "clear": "Clear",
    "itemsSelected": "{0} items selected",
    "selectAll": "Select all",
    "totalItems": "Total {0} items",
    "unselectAll": "Unselect all"
  },
  "treeList": {
    "empty": "No Data to List"
  },
  "users": {
    "additionalInfo": "Additional information",
    "agent": "User agent",
    "ban": {
      "delete": {
        "success": "Ban deleted successfully"
      },
      "evidence": "Evidence",
      "expiresAt": "Expiry Date",
      "reason": "Reason",
      "status": "Status",
      "success": " User Banned Successfully",
      "type": {
        "permanent": "Permanent",
        "temporary": "Temporary"
      }
    },
    "country": "Province / State",
    "create": "create user",
    "dateOfBirth": "date of birth",
    "delete": {
      "singleMessage": "Please confirm the deletion of the user.",
      "success": "The user was deleted successfully"
    },
    "devices": "Devices",
    "edit": "edit user",
    "emailAddress": {
      "new": "new email address",
      "primary": "primary email address",
      "title": "email address | email addresses"
    },
    "emailVerificationNote": "Email will be sent to the new address for verification",
    "empty": "No user",
    "externalIds": {
      "create": {
        "success": "External ID created successfully"
      },
      "provider": "External ID provider",
      "title": "External IDs",
      "update": {
        "success": "External ID updated successfully"
      },
      "value": "External ID value"
    },
    "firstName": "first name",
    "gameAccounts": {
      "create": {
        "success": "Game account {username} created"
      },
      "selectAccountType": "Select the account type",
      "title": "game accounts",
      "type": {
        "playstation": "PlayStation",
        "xbox": "XBox"
      },
      "update": {
        "noAccountId": "Missing parameter Account Id",
        "success": "Game account {username} updated"
      }
    },
    "gender": {
      "female": "female",
      "male": "male",
      "title": "gender",
      "unspecified": "unspecified"
    },
    "lastName": "last name",
    "lastSeen": "last seen",
    "nationality": "nationality",
    "occurrences": "Occurrences",
    "password": {
      "reset": {
        "success": "Reset password successfully",
        "title": "Reset password"
      }
    },
    "phone": {
      "primary": "primary phone number",
      "title": "Phone number | Phone numbers"
    },
    "phoneVerificationNote": "SMS will be sent to the new phone number for verification",
    "postcode": "postcode",
    "sendVerification": "send verification",
    "socialAccounts": "Social accounts",
    "subscription": "Subscription | Subscriptions",
    "success": {
      "success": "User suspended",
      "title": "User created successfully"
    },
    "suspend": {
      "success": "User suspended"
    },
    "telNo": "Tel No.",
    "userDetails": {
      "title": "user details",
      "update": {
        "error": "Error updating user details",
        "success": "User details updated successfully"
      }
    },
    "userId": "User ID",
    "userOverview": "user overview",
    "verificationEmailSent": "Verification email sent to {email}",
    "verificationSmsSent": "Verification SMS sent to {phone}"
  },
  "validation": {
    "configuration": {
      "duplicate": "You've entered a config key that already exists. Please remove the previous entry and try again"
    },
    "email": "Must be a valid email address",
    "gameAccountType": "You must choose a game account type",
    "hasLowercase": "Must contain at least one lowercase character",
    "hasNumber": "Must contain at least one number",
    "hasUppercase": "Must contain at least one uppercase character",
    "invalid": "All fields are required",
    "invalidTenant": "Invalid tenant",
    "invalidToken": "Invalid token",
    "maxLength": "Should be at most {n} characters long",
    "minAge": "The minimum allowed age is {n}",
    "minLength": "Should be at least {n} characters long",
    "passwordNotMatch": "Passwords do not match",
    "required": "This field is required"
  },
  "webhook": {
    "create": {
      "failed": "An error occurred while creating the webhook",
      "success": "Webhook created successfully"
    },
    "delete": {
      "failed": "An error occurred while deleting webhook(s)",
      "success": "Webhook(s) deleted successfully"
    },
    "edit": {
      "failed": "An error occurred while updating the webhook",
      "success": "Webhook updated successfully"
    },
    "id": "Webhook ID",
    "logs": {
      "callback": "Callback Url",
      "create": {
        "failed": "An error occurred while creating the notification log",
        "success": "Notification log created successfully"
      },
      "delete": {
        "failed": "An error occurred while deleting the notification(s) log",
        "success": "Notification(s) log deleted successfully"
      },
      "edit": {
        "failed": "An error occurred while updating the notification(s) log",
        "success": "Notifications log updated successfully"
      },
      "payload": "Payload",
      "title": "Webhook Log"
    },
    "manage": "Manage webhooks",
    "notifications_log": {
      "statusCode": "Status code",
      "title": "Notifications log"
    },
    "replay": {
      "failed": "Webhook Replay Failed",
      "success": "Webhook Replayed"
    },
    "secretKey": "Secret key",
    "target": "Target"
  },
  "webhooks": {
    "create": {
      "success": "Webhook created successfully"
    },
    "edit": {
      "success": "Webhook updated successfully"
    }
  },
  "whitelist": {
    "create": {
      "error": {
        "duplicate": "Duplicate external ID detected",
        "title": "Allowed Players list update failed!"
      },
      "success": "Allowed Players list created successfully"
    },
    "mapFields": "Map the correct fields",
    "preview": "Preview"
  }
}
