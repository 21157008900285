import type { App } from 'vue'
import { createI18n } from 'vue-i18n'
import en from '../../locales/en.json'

export const i18n = createI18n({
  fallbackLocale: 'en',
  legacy: false,
  locale: 'en',
  messages: {
    en,
  },
})

export function installI18n(app: App) {
  app.use(i18n)
}
